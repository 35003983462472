<template>
  <page :title="i18n.title" back>
    <div class="shop-warp">
      <div class="shop-banner">
        <img
          class="shop-banner-pic"
          :src="
            shopDetails.shopBanner || require('@/assets/imgs/banner-home.jpg')
          "
          alt=""
        />
      </div>
      <div class="shop-container">
        <div class="shop-main">
          <div class="shop-gourp shop-gourp-name">
            <p class="shop-gourp-val shopName">{{ shopDetails.shopName }}</p>
            <p class="shop-gourp-distance">{{ shopDetails.distance }}</p>
          </div>
          <div class="shop-gourp">
            <!-- <span class="shop-gourp-label">{{ i18n.shop_address }}</span> -->
            <p class="shop-gourp-val">{{ shopDetails.shopAddress1 || "-" }}</p>
          </div>
          <div class="shop-gourp-time">
            <span class="shop-gourp-label">{{ i18n.shop_business_hours }}</span>
            <div class="shop-gourp-row">
              <p class="shop-gourp-val">{{ shopDetails.shopTime || "-" }}</p>
              <p class="shop-gourp-val">{{ priceStrategy(shopDetails) }}</p>
            </div>
          </div>

          <div class="shop-row">
            <div class="shop-gourp">
              <!-- <span class="shop-gourp-label">{{ i18n.shop_power_banks }}</span> -->
              <img src="@/assets/imgs/shop/icon_shop_cabinet.png" alt="" />
              <p class="shop-gourp-val">
                {{ shopDetails.freeNum }} {{ i18n.shop_power_banks }}
              </p>
            </div>
            <div class="shop-gourp">
              <!-- <span class="shop-gourp-label">{{ i18n.shop_empty_slots }}</span> -->
              <img src="@/assets/imgs/shop/icon_charge_available.png" alt="" />
              <p class="shop-gourp-val">
                {{ parseInt(shopDetails.batteryNum - shopDetails.freeNum) }}
                {{ i18n.shop_empty_slots }}
              </p>
            </div>
          </div>
        </div>
        <div class="shop-footer">
          <div class="shop-des" @click="goWebsite()">
            <img
              alt=""
              class="shop-des-pic"
              src="../../assets/imgs/icon_shop_earth.png"
            />
            {{ i18n.shop_station_website }}
          </div>
          <div class="btn" @click="goNearbyShop">
            {{ i18n.shop_directions_station }}
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import { mapState } from "vuex"
export default {
  components: {},
  computed: {
    ...mapState(["system", "shopInfo"]),
    i18n() {
      return this.$t("shop")
    }
  },
  data() {
    return {
      error: "",
      loading: false,
      shopDetails: {}
    }
  },
  methods: {
    goRouter(path) {
      this.$router.push(path)
    },
    goWebsite() {
      let _domain = `${process.env.VUE_APP_DOMAIN}`
      _domain = _domain.replace("//m.", "//www.")
      window.open(_domain)
    },
    // 附近站点
    goNearbyShop() {
      const jumpPath = `https://www.google.com/maps/dir/`
      window.open(
        `${jumpPath}?api=1&destination=${this.shopDetails.latitude},${this.shopDetails.longitude}&travelmode=bicycling`
      )
    },
    // 获取状态
    onlineStatus(infoStatus) {
      return {
        text: this.$t(`map.${infoStatus}`),
        class: infoStatus === "在线" ? "online" : ""
      }
    },
    /**
     * 店铺详情
     */
    getShopDetails(id) {
      const shop = this.shopInfo
      if (shop.id === parseInt(id)) {
        this.shopDetails = shop
      }
    },

    // 价格策略
    priceStrategy(shop = {}) {
      const strategy = shop.priceStrategy || shop
      let keepT = this.$t("home.minute")
      // 计时 pPriceMinute  单位 priceUnit 0-分钟 1-小时 2-天
      if (strategy.priceUnit === 1) {
        keepT = this.$t("home.hour")
      } else if (strategy.priceUnit === 2) {
        keepT = this.$t("home.days")
      }
      const price = `${shop.currencyName + shop.pjifei}`
      return `${price} / ${strategy.pjifeiDanwei} ${keepT}`
    }
  },

  mounted() {
    const { pathMatch } = this.$route.params
    this.getShopDetails(pathMatch)
  },
  destroyed() {
    // this.$store.commit("shopInfo", null)
  }
}
</script>
<style lang="less" scoped>
.shop-warp {
  position: relative;
  min-height: calc(100vh - 108px);
  padding: 108px 0 0;
  background-color: #fff;
}
.shop-banner {
  width: 100%;
  min-height: 300px;
  max-height: 400px;
  .shop-banner-pic {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.shop-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 64vh;
  border-radius: 84px 84px 0 0;
  background-color: #fff;
  padding-top: 86px;
}
.shop-main {
  position: relative;
  margin: 0 56px;
  border-radius: 20px;
  overflow: hidden;
  // box-shadow: 0 0 2px 2px #d5d5d5;
  .shop-row {
    display: flex;
    margin-top: 80px;
    justify-content: stretch;
    .shop-gourp {
      flex: 1;
      display: flex;
      margin: 0;
      img {
        width: 36px;
        margin-right: 6px;
        object-fit: contain;
      }
    }
  }
  .shop-gourp {
    // margin: 18px 36px 0;
    font-size: 26px;
    color: rgba(0, 0, 0, 0.5);
    &-val {
      color: #333;
      font-size: 26px;
      margin: 6px 0 0;
    }
    &-row {
      display: flex;
    }
    .shopName {
      font-size: 36px;
      font-weight: bold;
    }
    &-name {
      display: flex;
      justify-content: space-between;
    }
    &-distance {
      margin: 0;
      color: #333;
      font-size: 28px;
    }
    &-time {
      margin-top: 60px;
      .shop-gourp-label {
        color: #75bb48;
      }
      .shop-gourp-row {
        justify-content: space-between;
      }
    }
  }
}
.shop-footer {
  position: relative;
  margin: 200px 40px 0;
  .shop-des {
    height: 42px;
    margin-bottom: 24px;
    border-radius: 56px;
    color: #2f7afc;
    text-align: center;
    background-color: transparent;
    &-pic {
      width: 32px;
      vertical-align: middle;
    }
  }
  .btn {
    border-radius: 56px;
  }
}
</style>
